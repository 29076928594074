<template>
  <div class="content content-full content-boxed" data-cy="company-users-view">
    <div v-show="errorAction.update" class="alert alert-danger">{{ errorAction.update }}</div>
    <div class="block block-rounded">
      <div class="block-content">
        <div v-if="loadingAction.get" class="d-flex justify-content-center mb-5">
          <div class="text-center">
            <div class="spinner-border spinner-lg text-secondary mb-4" role="status">
              <span class="sr-only">{{ $t('LOADING_COMPANY') }}</span>
            </div>
            <div class="font-w600">{{ $t('LOADING_COMPANY') }}</div>
          </div>
        </div>
        <div v-else>
          <CompanyForm v-model="form" :error="errorAction.update || {}" />
          <div v-if="['company_owner', 'admin'].some(r => $auth.roles.includes(r))" class="text-right pb-4">
            <span v-if="isUnsavedChanges">{{ $t('UNSAVED_CHANGES') }}</span>
            <button type="submit" class="btn btn-primary" :disabled="loadingAction.update" @click="onUpdateCompany">
              <span v-if="loadingAction.update"><i class="fa fa-spin fa-spinner mr-1"></i> {{ $t('UPDATING') }}</span>
              <span v-else><i class="fa fa-check-circle mr-1"></i> {{ $t('UPDATE_COMPANY') }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { isEqual as _isEqual } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import CompanyForm from '@/components/forms/CompanyForm';

export default {
  name: 'CompanyEdit',
  components: {
    CompanyForm
  },
  data() {
    return {
      form: {}
    };
  },
  computed: {
    ...mapGetters({
      company: 'company/company',
      loadingAction: 'company/loadingAction',
      errorAction: 'company/errorAction'
    }),
    isUnsavedChanges() {
      return _isEqual(this.form, this.company);
    }
  },
  async mounted() {
    await this.getCompany({ id: this.$auth.companyId });
    this.form = { ...this.company };
  },
  methods: {
    ...mapActions({
      getCompany: 'company/get',
      updateCompany: 'company/update'
    }),
    async onUpdateCompany() {
      await this.updateCompany({
        id: this.$auth.companyId,
        data: {
          ...this.form,
          settings: {
            ...this.company.settings,
            ...this.form.settings
          }
        }
      });
      this.$toasted.success(this.$t('COMPANY_UPDATE_SUCCESS'), { position: 'bottom-right', duration: 3000 });
    }
  }
};
</script>
