var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content content-full content-boxed",
    attrs: {
      "data-cy": "company-users-view"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errorAction.update,
      expression: "errorAction.update"
    }],
    staticClass: "alert alert-danger"
  }, [_vm._v(_vm._s(_vm.errorAction.update))]), _c('div', {
    staticClass: "block block-rounded"
  }, [_c('div', {
    staticClass: "block-content"
  }, [_vm.loadingAction.get ? _c('div', {
    staticClass: "d-flex justify-content-center mb-5"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "spinner-border spinner-lg text-secondary mb-4",
    attrs: {
      "role": "status"
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._v(_vm._s(_vm.$t('LOADING_COMPANY')))])]), _c('div', {
    staticClass: "font-w600"
  }, [_vm._v(_vm._s(_vm.$t('LOADING_COMPANY')))])])]) : _c('div', [_c('CompanyForm', {
    attrs: {
      "error": _vm.errorAction.update || {}
    },
    model: {
      value: _vm.form,
      callback: function callback($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }), ['company_owner', 'admin'].some(function (r) {
    return _vm.$auth.roles.includes(r);
  }) ? _c('div', {
    staticClass: "text-right pb-4"
  }, [_vm.isUnsavedChanges ? _c('span', [_vm._v(_vm._s(_vm.$t('UNSAVED_CHANGES')))]) : _vm._e(), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "submit",
      "disabled": _vm.loadingAction.update
    },
    on: {
      "click": _vm.onUpdateCompany
    }
  }, [_vm.loadingAction.update ? _c('span', [_c('i', {
    staticClass: "fa fa-spin fa-spinner mr-1"
  }), _vm._v(" " + _vm._s(_vm.$t('UPDATING')))]) : _c('span', [_c('i', {
    staticClass: "fa fa-check-circle mr-1"
  }), _vm._v(" " + _vm._s(_vm.$t('UPDATE_COMPANY')))])])]) : _vm._e()], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }